// File generated by salient-types.
export enum InputMode {
    NONE = 0,
    SERVO = 1,
    CAN = 2,
    USB = 3,
}

export enum ControlMode {
    TORQUE = 0,
    SPEED = 1,
    VOLTAGE = 2,
    POSITION = 3,
}

export enum ControllerState {
    BOOT = 0,
    CALIBRATION = 1,
    L_R_DETECTION = 2,
    ENCODER_DETECTION = 3,
    STOPPED = 4,
    RUNNING = 5,
    TEST = 6,
    UPDATING_FIRMWARE = 7,
    FAULT = 8,
}

export enum FaultCode {
    NO_FAULT = 0,
    BAD_CURRENT_MEASURE = 1,
    OVERVOLTAGE = 2,
    OVERTEMPERATURE = 3,
    OVERCURRENT = 4,
    BAD_CONFIG = 5,
    GATE_DRIVE_UNDERVOLTAGE = 6,
    LOGIC_UNDERVOLTAGE = 7,
    PARAM_DETECT_FAIL = 8,
    NAN_OUTPUT = 9,
    BAD_PARAM = 10,
    INVALID_INPUT = 11,
    ENCODER_CALIBRATION_FAILED = 12,
    GATE_DRIVE_OVERCURRENT = 13,
    GATE_DRIVE_FAULT = 14,
    GATE_DRIVE_OVERTEMPERATURE_SHUTDOWN = 15,
    GATE_DRIVE_OVERTEMPERATURE_WARNING = 16,
    GATE_DRIVE_CHARGE_PUMP_VGLS_UNDERVOLTAGE = 17,
    GATE_DRIVE_INIT_FAILED = 18,
}

export enum SensorMode {
    SENSORLESS = 0,
    ENCODER = 1,
    OPENLOOP = 2,
}

export enum EncoderCalibStatus {
    UNKNOWN = 0,
    COMPLETE = 1,
    ONGOING = 2,
    FAILURE_INDEX_MISSING = 3,
    FAILURE_POSITION_ERROR = 4,
    FAILURE_TIMEOUT = 5,
    FAILURE_ABORTED = 6,
}

export enum SmFwBuildType {
    EXPERIMENTAL = 0,
    CANDIDATE = 1,
    RELEASE = 2,
}
export enum EncoderDirection {
    COUNT_DOWN = -1,
    UNKNOWN = 0,
    COUNT_UP = 1,
}

export enum FluxWeakening {
    UNKNOWN = -1,
    DISABLE = 0,
    ENABLE = 1,
}

export enum Mtpa {
    UNKNOWN = -1,
    DISABLE = 0,
    ENABLE = 1,
}

export enum PacketId {
    INVALID = 0,
    HANDSHAKE = 1,
    START_PARAM_DETECT = 2,
    WRITE_INPUT_CONFIG = 3,
    WRITE_POWER_CONFIG = 4,
    READ_FAULT = 5,
    READ_TELEMETRY = 6,
    READ_MOTOR_PARAM = 7,
    READ_INPUT_CONFIG = 8,
    READ_POWER_CONFIG = 9,
    WRITE_PARAMETERS = 10,
    WRITE_CONTROL_CONFIG = 11,
    WRITE_SENSOR_MODE = 12,
    READ_SENSOR_MODE = 13,
    START_ENCODER_CAL = 14,
    GET_ENCODER_CAL_RESULT = 15,
    ENCODER_CAL_RESULT = 16,
    WRITE_OPEN_LOOP_CONFIG = 17,
    READ_CONTROL_MODE = 18,
    WRITE_CAN_ID = 19,
    READ_CAN_ID = 20,
    WRITE_ESC_ID = 21,
    READ_ESC_ID = 22,
    GET_BUILD_INFO = 23,
    READ_MTPA_STATUS = 24,
    READ_FLUX_WEAKENING_STATUS = 25,
    SET_USB_THROTTLE_CONTROL = 64,
    FAULT_DATA = 127,
    REALTIME_DATA = 128,
    FIRMWARE_UPDATE = 254,
    USER_DEFINED = 255,
}

export interface IInvalidRequest {
}

export interface IInvalidResponse {
}

export interface IHandshakeRequest {
}

export interface IHandshakeResponse {
    versionMajor: number;
    versionMinor: number;
    versionBuild: number;
    resistance: number;
    ld: number;
    lq: number;
    fluxLinkage: number;
    gitHash: number;
    deviceId1: number;
    deviceId2: number;
    deviceId3: number;
}

export interface IStartParamDetectRequest {
    paramDetectMaxCurrent: number;
    paramDetectMaxRpm: number;
}

export interface IStartParamDetectResponse {
    resistance: number;
    ld: number;
    lq: number;
    fluxLinkage: number;
}

export interface IWriteInputConfigRequest {
    inputLimitUpper: number;
    inputLimitLower: number;
    inputLimitCenter: number;
    inputMode: InputMode;
    alwaysOn: number;
}

export interface IWriteInputConfigResponse {
    inputLimitUpper: number;
    inputLimitLower: number;
    inputLimitCenter: number;
    inputMode: InputMode;
    alwaysOn: number;
}

export interface IWritePowerConfigRequest {
    motorCurrentLimit: number;
    batteryCurrentLimit: number;
    overvoltageThreshold: number;
    undervoltageThreshold: number;
    overcurrentThreshold: number;
    overtemperatureThreshold: number;
}

export interface IWritePowerConfigResponse {
    motorCurrentLimit: number;
    batteryCurrentLimit: number;
    overvoltageThreshold: number;
    undervoltageThreshold: number;
    overcurrentThreshold: number;
    overtemperatureThreshold: number;
}

export interface IReadFaultRequest {
}

export interface IReadFaultResponse {
}

export interface IReadTelemetryRequest {
}

export interface IReadTelemetryResponse {
}

export interface IReadMotorParamRequest {
}

export interface IReadMotorParamResponse {
}

export interface IReadInputConfigRequest {
}

export interface IReadInputConfigResponse {
    inputLimitUpper: number;
    inputLimitLower: number;
    inputLimitCenter: number;
    inputMode: InputMode;
    alwaysOn: number;
}

export interface IReadPowerConfigRequest {
}

export interface IReadPowerConfigResponse {
    motorCurrentLimit: number;
    batteryCurrentLimit: number;
    overvoltageThreshold: number;
    undervoltageThreshold: number;
    overcurrentThreshold: number;
    overtemperatureThreshold: number;
}

export interface IWriteParametersRequest {
    resistance: number;
    ld: number;
    lq: number;
    fluxLinkage: number;
}

export interface IWriteParametersResponse {
    resistance: number;
    ld: number;
    lq: number;
    fluxLinkage: number;
}

export interface IWriteControlConfigRequest {
    motorMaxSpeed: number;
    polePairCount: number;
    speedControlProportionalGain: number;
    speedControlIntegralGain: number;
    positionControlProportionalGain: number;
    controlMode: ControlMode;
    mtpa: Mtpa;
    fluxWeakening: FluxWeakening;
}

export interface IWriteControlConfigResponse {
    motorMaxSpeed: number;
    polePairCount: number;
    speedControlProportionalGain: number;
    speedControlIntegralGain: number;
    positionControlProportionalGain: number;
    controlMode: ControlMode;
    mtpa: Mtpa;
    fluxWeakening: FluxWeakening;
}

export interface IWriteSensorModeRequest {
    sensorMode: SensorMode;
    encoderCpr: number;
}

export interface IWriteSensorModeResponse {
    sensorMode: SensorMode;
    encoderCpr: number;
}

export interface IReadSensorModeRequest {
}

export interface IReadSensorModeResponse {
    sensorMode: SensorMode;
    encoderCpr: number;
    encoderOffset: number;
    encoderDirection: EncoderDirection;
}

export interface IStartEncoderCalRequest {
}

export interface IStartEncoderCalResponse {
}

export interface IGetEncoderCalResultRequest {
}

export interface IGetEncoderCalResultResponse {
    offset: number;
    status: EncoderCalibStatus;
    direction: EncoderDirection;
}

export interface IEncoderCalResultRequest {
}

export interface IEncoderCalResultResponse {
    offset: number;
    status: EncoderCalibStatus;
    direction: EncoderDirection;
}

export interface IWriteOpenLoopConfigRequest {
    accelerationRamp: number;
}

export interface IWriteOpenLoopConfigResponse {
    accelerationRamp: number;
}

export interface IReadControlModeRequest {
}

export interface IReadControlModeResponse {
    controlMode: ControlMode;
}

export interface IWriteCanIdRequest {
    canId: number;
}

export interface IWriteCanIdResponse {
    canId: number;
}

export interface IReadCanIdRequest {
}

export interface IReadCanIdResponse {
    canId: number;
}

export interface IWriteEscIdRequest {
    escIndex: number;
}

export interface IWriteEscIdResponse {
    escIndex: number;
}

export interface IReadEscIdRequest {
}

export interface IReadEscIdResponse {
    escIndex: number;
}

export interface IGetBuildInfoRequest {
}

export interface IGetBuildInfoResponse {
    year: number;
    month: number;
    day: number;
    increment: number;
    buildType: SmFwBuildType;
    dirty: number;
}

export interface IReadMtpaStatusRequest {
}

export interface IReadMtpaStatusResponse {
    mtpa: Mtpa;
}

export interface IReadFluxWeakeningStatusRequest {
}

export interface IReadFluxWeakeningStatusResponse {
    fluxWeakening: FluxWeakening;
}

export interface ISetUsbThrottleControlRequest {
    value: number;
}

export interface ISetUsbThrottleControlResponse {
    value: number;
}

export interface IFaultDataMessage {
    code: number;
}

export interface IRealtimeDataMessage {
    motorSpeed: number;
    motorCurrent: number;
    batteryVoltage: number;
    batteryCurrent: number;
    servoPulse: number;
    inverterTemp: number;
    controllerState: ControllerState;
    faultCode: FaultCode;
    uptimeMs: number;
}

export interface IFirmwareUpdateRequest {
}

export interface IFirmwareUpdateResponse {
}

export interface IUserDefinedMessage {
}

export const serializeInvalidRequest = ( message: IInvalidRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.INVALID );
    return dv;
};

export const serializeInvalidResponse = ( message: IInvalidResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.INVALID );
    return dv;
};

export const serializeHandshakeRequest = ( message: IHandshakeRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.HANDSHAKE );
    return dv;
};

export const serializeHandshakeResponse = ( message: IHandshakeResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.HANDSHAKE );
    dv.setUint32( 4, message.versionMajor, true );
    dv.setUint32( 8, message.versionMinor, true );
    dv.setUint32( 12, message.versionBuild, true );
    dv.setFloat32( 16, message.resistance, true );
    dv.setFloat32( 20, message.ld, true );
    dv.setFloat32( 24, message.lq, true );
    dv.setFloat32( 28, message.fluxLinkage, true );
    dv.setUint32( 32, message.gitHash, true );
    dv.setUint32( 36, message.deviceId1, true );
    dv.setUint32( 40, message.deviceId2, true );
    dv.setUint32( 44, message.deviceId3, true );
    return dv;
};

export const serializeStartParamDetectRequest = ( message: IStartParamDetectRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.START_PARAM_DETECT );
    dv.setFloat32( 4, message.paramDetectMaxCurrent, true );
    dv.setFloat32( 8, message.paramDetectMaxRpm, true );
    return dv;
};

export const serializeStartParamDetectResponse = ( message: IStartParamDetectResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.START_PARAM_DETECT );
    dv.setFloat32( 4, message.resistance, true );
    dv.setFloat32( 8, message.ld, true );
    dv.setFloat32( 12, message.lq, true );
    dv.setFloat32( 16, message.fluxLinkage, true );
    return dv;
};

export const serializeWriteInputConfigRequest = ( message: IWriteInputConfigRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_INPUT_CONFIG );
    dv.setUint32( 4, message.inputLimitUpper, true );
    dv.setUint32( 8, message.inputLimitLower, true );
    dv.setUint32( 12, message.inputLimitCenter, true );
    dv.setUint8( 16, message.inputMode );
    dv.setUint8( 17, message.alwaysOn );
    return dv;
};

export const serializeWriteInputConfigResponse = ( message: IWriteInputConfigResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_INPUT_CONFIG );
    dv.setUint32( 4, message.inputLimitUpper, true );
    dv.setUint32( 8, message.inputLimitLower, true );
    dv.setUint32( 12, message.inputLimitCenter, true );
    dv.setUint8( 16, message.inputMode );
    dv.setUint8( 17, message.alwaysOn );
    return dv;
};

export const serializeWritePowerConfigRequest = ( message: IWritePowerConfigRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_POWER_CONFIG );
    dv.setFloat32( 4, message.motorCurrentLimit, true );
    dv.setFloat32( 8, message.batteryCurrentLimit, true );
    dv.setFloat32( 12, message.overvoltageThreshold, true );
    dv.setFloat32( 16, message.undervoltageThreshold, true );
    dv.setFloat32( 20, message.overcurrentThreshold, true );
    dv.setFloat32( 24, message.overtemperatureThreshold, true );
    return dv;
};

export const serializeWritePowerConfigResponse = ( message: IWritePowerConfigResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_POWER_CONFIG );
    dv.setFloat32( 4, message.motorCurrentLimit, true );
    dv.setFloat32( 8, message.batteryCurrentLimit, true );
    dv.setFloat32( 12, message.overvoltageThreshold, true );
    dv.setFloat32( 16, message.undervoltageThreshold, true );
    dv.setFloat32( 20, message.overcurrentThreshold, true );
    dv.setFloat32( 24, message.overtemperatureThreshold, true );
    return dv;
};

export const serializeReadFaultRequest = ( message: IReadFaultRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_FAULT );
    return dv;
};

export const serializeReadFaultResponse = ( message: IReadFaultResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_FAULT );
    return dv;
};

export const serializeReadTelemetryRequest = ( message: IReadTelemetryRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_TELEMETRY );
    return dv;
};

export const serializeReadTelemetryResponse = ( message: IReadTelemetryResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_TELEMETRY );
    return dv;
};

export const serializeReadMotorParamRequest = ( message: IReadMotorParamRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_MOTOR_PARAM );
    return dv;
};

export const serializeReadMotorParamResponse = ( message: IReadMotorParamResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_MOTOR_PARAM );
    return dv;
};

export const serializeReadInputConfigRequest = ( message: IReadInputConfigRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_INPUT_CONFIG );
    return dv;
};

export const serializeReadInputConfigResponse = ( message: IReadInputConfigResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_INPUT_CONFIG );
    dv.setUint32( 4, message.inputLimitUpper, true );
    dv.setUint32( 8, message.inputLimitLower, true );
    dv.setUint32( 12, message.inputLimitCenter, true );
    dv.setUint8( 16, message.inputMode );
    dv.setUint8( 17, message.alwaysOn );
    return dv;
};

export const serializeReadPowerConfigRequest = ( message: IReadPowerConfigRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_POWER_CONFIG );
    return dv;
};

export const serializeReadPowerConfigResponse = ( message: IReadPowerConfigResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_POWER_CONFIG );
    dv.setFloat32( 4, message.motorCurrentLimit, true );
    dv.setFloat32( 8, message.batteryCurrentLimit, true );
    dv.setFloat32( 12, message.overvoltageThreshold, true );
    dv.setFloat32( 16, message.undervoltageThreshold, true );
    dv.setFloat32( 20, message.overcurrentThreshold, true );
    dv.setFloat32( 24, message.overtemperatureThreshold, true );
    return dv;
};

export const serializeWriteParametersRequest = ( message: IWriteParametersRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_PARAMETERS );
    dv.setFloat32( 4, message.resistance, true );
    dv.setFloat32( 8, message.ld, true );
    dv.setFloat32( 12, message.lq, true );
    dv.setFloat32( 16, message.fluxLinkage, true );
    return dv;
};

export const serializeWriteParametersResponse = ( message: IWriteParametersResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_PARAMETERS );
    dv.setFloat32( 4, message.resistance, true );
    dv.setFloat32( 8, message.ld, true );
    dv.setFloat32( 12, message.lq, true );
    dv.setFloat32( 16, message.fluxLinkage, true );
    return dv;
};

export const serializeWriteControlConfigRequest = ( message: IWriteControlConfigRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_CONTROL_CONFIG );
    dv.setFloat32( 4, message.motorMaxSpeed, true );
    dv.setFloat32( 8, message.polePairCount, true );
    dv.setFloat32( 12, message.speedControlProportionalGain, true );
    dv.setFloat32( 16, message.speedControlIntegralGain, true );
    dv.setFloat32( 20, message.positionControlProportionalGain, true );
    dv.setUint8( 24, message.controlMode );
    dv.setInt8( 25, message.mtpa );
    dv.setInt8( 26, message.fluxWeakening );
    return dv;
};

export const serializeWriteControlConfigResponse = ( message: IWriteControlConfigResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_CONTROL_CONFIG );
    dv.setFloat32( 4, message.motorMaxSpeed, true );
    dv.setFloat32( 8, message.polePairCount, true );
    dv.setFloat32( 12, message.speedControlProportionalGain, true );
    dv.setFloat32( 16, message.speedControlIntegralGain, true );
    dv.setFloat32( 20, message.positionControlProportionalGain, true );
    dv.setUint8( 24, message.controlMode );
    dv.setInt8( 25, message.mtpa );
    dv.setInt8( 26, message.fluxWeakening );
    return dv;
};

export const serializeWriteSensorModeRequest = ( message: IWriteSensorModeRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_SENSOR_MODE );
    dv.setUint8( 4, message.sensorMode );
    dv.setUint32( 5, message.encoderCpr, true );
    return dv;
};

export const serializeWriteSensorModeResponse = ( message: IWriteSensorModeResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_SENSOR_MODE );
    dv.setUint8( 4, message.sensorMode );
    dv.setUint32( 5, message.encoderCpr, true );
    return dv;
};

export const serializeReadSensorModeRequest = ( message: IReadSensorModeRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_SENSOR_MODE );
    return dv;
};

export const serializeReadSensorModeResponse = ( message: IReadSensorModeResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_SENSOR_MODE );
    dv.setUint8( 4, message.sensorMode );
    dv.setUint32( 5, message.encoderCpr, true );
    dv.setFloat32( 9, message.encoderOffset, true );
    dv.setInt8( 13, message.encoderDirection );
    return dv;
};

export const serializeStartEncoderCalRequest = ( message: IStartEncoderCalRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.START_ENCODER_CAL );
    return dv;
};

export const serializeStartEncoderCalResponse = ( message: IStartEncoderCalResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.START_ENCODER_CAL );
    return dv;
};

export const serializeGetEncoderCalResultRequest = ( message: IGetEncoderCalResultRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.GET_ENCODER_CAL_RESULT );
    return dv;
};

export const serializeGetEncoderCalResultResponse = ( message: IGetEncoderCalResultResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.GET_ENCODER_CAL_RESULT );
    dv.setFloat32( 4, message.offset, true );
    dv.setUint8( 8, message.status );
    dv.setInt8( 9, message.direction );
    return dv;
};

export const serializeEncoderCalResultRequest = ( message: IEncoderCalResultRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.ENCODER_CAL_RESULT );
    return dv;
};

export const serializeEncoderCalResultResponse = ( message: IEncoderCalResultResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.ENCODER_CAL_RESULT );
    dv.setFloat32( 4, message.offset, true );
    dv.setUint8( 8, message.status );
    dv.setInt8( 9, message.direction );
    return dv;
};

export const serializeWriteOpenLoopConfigRequest = ( message: IWriteOpenLoopConfigRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_OPEN_LOOP_CONFIG );
    dv.setFloat32( 4, message.accelerationRamp, true );
    return dv;
};

export const serializeWriteOpenLoopConfigResponse = ( message: IWriteOpenLoopConfigResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_OPEN_LOOP_CONFIG );
    dv.setFloat32( 4, message.accelerationRamp, true );
    return dv;
};

export const serializeReadControlModeRequest = ( message: IReadControlModeRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_CONTROL_MODE );
    return dv;
};

export const serializeReadControlModeResponse = ( message: IReadControlModeResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_CONTROL_MODE );
    dv.setUint8( 4, message.controlMode );
    return dv;
};

export const serializeWriteCanIdRequest = ( message: IWriteCanIdRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_CAN_ID );
    dv.setUint32( 4, message.canId, true );
    return dv;
};

export const serializeWriteCanIdResponse = ( message: IWriteCanIdResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_CAN_ID );
    dv.setUint32( 4, message.canId, true );
    return dv;
};

export const serializeReadCanIdRequest = ( message: IReadCanIdRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_CAN_ID );
    return dv;
};

export const serializeReadCanIdResponse = ( message: IReadCanIdResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_CAN_ID );
    dv.setUint32( 4, message.canId, true );
    return dv;
};

export const serializeWriteEscIdRequest = ( message: IWriteEscIdRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_ESC_ID );
    dv.setUint32( 4, message.escIndex, true );
    return dv;
};

export const serializeWriteEscIdResponse = ( message: IWriteEscIdResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.WRITE_ESC_ID );
    dv.setUint32( 4, message.escIndex, true );
    return dv;
};

export const serializeReadEscIdRequest = ( message: IReadEscIdRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_ESC_ID );
    return dv;
};

export const serializeReadEscIdResponse = ( message: IReadEscIdResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_ESC_ID );
    dv.setUint32( 4, message.escIndex, true );
    return dv;
};

export const serializeGetBuildInfoRequest = ( message: IGetBuildInfoRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.GET_BUILD_INFO );
    return dv;
};

export const serializeGetBuildInfoResponse = ( message: IGetBuildInfoResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.GET_BUILD_INFO );
    dv.setUint32( 4, message.year, true );
    dv.setUint32( 8, message.month, true );
    dv.setUint32( 12, message.day, true );
    dv.setUint32( 16, message.increment, true );
    dv.setUint8( 20, message.buildType );
    dv.setUint32( 21, message.dirty, true );
    return dv;
};

export const serializeReadMtpaStatusRequest = ( message: IReadMtpaStatusRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_MTPA_STATUS );
    return dv;
};

export const serializeReadMtpaStatusResponse = ( message: IReadMtpaStatusResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_MTPA_STATUS );
    dv.setInt8( 4, message.mtpa );
    return dv;
};

export const serializeReadFluxWeakeningStatusRequest = ( message: IReadFluxWeakeningStatusRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_FLUX_WEAKENING_STATUS );
    return dv;
};

export const serializeReadFluxWeakeningStatusResponse = ( message: IReadFluxWeakeningStatusResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.READ_FLUX_WEAKENING_STATUS );
    dv.setInt8( 4, message.fluxWeakening );
    return dv;
};

export const serializeSetUsbThrottleControlRequest = ( message: ISetUsbThrottleControlRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.SET_USB_THROTTLE_CONTROL );
    dv.setUint32( 4, message.value, true );
    return dv;
};

export const serializeSetUsbThrottleControlResponse = ( message: ISetUsbThrottleControlResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.SET_USB_THROTTLE_CONTROL );
    dv.setUint32( 4, message.value, true );
    return dv;
};

export const serializeFaultDataMessage = ( message: IFaultDataMessage ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.FAULT_DATA );
    dv.setUint32( 4, message.code, true );
    return dv;
};

export const serializeRealtimeDataMessage = ( message: IRealtimeDataMessage ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.REALTIME_DATA );
    dv.setFloat32( 4, message.motorSpeed, true );
    dv.setFloat32( 8, message.motorCurrent, true );
    dv.setFloat32( 12, message.batteryVoltage, true );
    dv.setFloat32( 16, message.batteryCurrent, true );
    dv.setFloat32( 20, message.servoPulse, true );
    dv.setFloat32( 24, message.inverterTemp, true );
    dv.setUint8( 28, message.controllerState );
    dv.setUint8( 29, message.faultCode );
    dv.setUint32( 30, message.uptimeMs, true );
    return dv;
};

export const serializeFirmwareUpdateRequest = ( message: IFirmwareUpdateRequest ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.FIRMWARE_UPDATE );
    return dv;
};

export const serializeFirmwareUpdateResponse = ( message: IFirmwareUpdateResponse ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.FIRMWARE_UPDATE );
    return dv;
};

export const serializeUserDefinedMessage = ( message: IUserDefinedMessage ): DataView => {
    const dv = new DataView( new ArrayBuffer( 64 ) );
    dv.setUint8( 0, PacketId.USER_DEFINED );
    return dv;
};
export const deserializeInvalidRequest = ( dv: DataView ): IInvalidRequest => {
    return {
    };
};

export const deserializeInvalidResponse = ( dv: DataView ): IInvalidResponse => {
    return {
    };
};

export const deserializeHandshakeRequest = ( dv: DataView ): IHandshakeRequest => {
    return {
    };
};

export const deserializeHandshakeResponse = ( dv: DataView ): IHandshakeResponse => {
    return {
        versionMajor: dv.getUint32( 4, true ),
        versionMinor: dv.getUint32( 8, true ),
        versionBuild: dv.getUint32( 12, true ),
        resistance: dv.getFloat32( 16, true ),
        ld: dv.getFloat32( 20, true ),
        lq: dv.getFloat32( 24, true ),
        fluxLinkage: dv.getFloat32( 28, true ),
        gitHash: dv.getUint32( 32, true ),
        deviceId1: dv.getUint32( 36, true ),
        deviceId2: dv.getUint32( 40, true ),
        deviceId3: dv.getUint32( 44, true ),
    };
};

export const deserializeStartParamDetectRequest = ( dv: DataView ): IStartParamDetectRequest => {
    return {
        paramDetectMaxCurrent: dv.getFloat32( 4, true ),
        paramDetectMaxRpm: dv.getFloat32( 8, true ),
    };
};

export const deserializeStartParamDetectResponse = ( dv: DataView ): IStartParamDetectResponse => {
    return {
        resistance: dv.getFloat32( 4, true ),
        ld: dv.getFloat32( 8, true ),
        lq: dv.getFloat32( 12, true ),
        fluxLinkage: dv.getFloat32( 16, true ),
    };
};

export const deserializeWriteInputConfigRequest = ( dv: DataView ): IWriteInputConfigRequest => {
    return {
        inputLimitUpper: dv.getUint32( 4, true ),
        inputLimitLower: dv.getUint32( 8, true ),
        inputLimitCenter: dv.getUint32( 12, true ),
        inputMode: dv.getUint8( 16 ),
        alwaysOn: dv.getUint8( 17 ),
    };
};

export const deserializeWriteInputConfigResponse = ( dv: DataView ): IWriteInputConfigResponse => {
    return {
        inputLimitUpper: dv.getUint32( 4, true ),
        inputLimitLower: dv.getUint32( 8, true ),
        inputLimitCenter: dv.getUint32( 12, true ),
        inputMode: dv.getUint8( 16 ),
        alwaysOn: dv.getUint8( 17 ),
    };
};

export const deserializeWritePowerConfigRequest = ( dv: DataView ): IWritePowerConfigRequest => {
    return {
        motorCurrentLimit: dv.getFloat32( 4, true ),
        batteryCurrentLimit: dv.getFloat32( 8, true ),
        overvoltageThreshold: dv.getFloat32( 12, true ),
        undervoltageThreshold: dv.getFloat32( 16, true ),
        overcurrentThreshold: dv.getFloat32( 20, true ),
        overtemperatureThreshold: dv.getFloat32( 24, true ),
    };
};

export const deserializeWritePowerConfigResponse = ( dv: DataView ): IWritePowerConfigResponse => {
    return {
        motorCurrentLimit: dv.getFloat32( 4, true ),
        batteryCurrentLimit: dv.getFloat32( 8, true ),
        overvoltageThreshold: dv.getFloat32( 12, true ),
        undervoltageThreshold: dv.getFloat32( 16, true ),
        overcurrentThreshold: dv.getFloat32( 20, true ),
        overtemperatureThreshold: dv.getFloat32( 24, true ),
    };
};

export const deserializeReadFaultRequest = ( dv: DataView ): IReadFaultRequest => {
    return {
    };
};

export const deserializeReadFaultResponse = ( dv: DataView ): IReadFaultResponse => {
    return {
    };
};

export const deserializeReadTelemetryRequest = ( dv: DataView ): IReadTelemetryRequest => {
    return {
    };
};

export const deserializeReadTelemetryResponse = ( dv: DataView ): IReadTelemetryResponse => {
    return {
    };
};

export const deserializeReadMotorParamRequest = ( dv: DataView ): IReadMotorParamRequest => {
    return {
    };
};

export const deserializeReadMotorParamResponse = ( dv: DataView ): IReadMotorParamResponse => {
    return {
    };
};

export const deserializeReadInputConfigRequest = ( dv: DataView ): IReadInputConfigRequest => {
    return {
    };
};

export const deserializeReadInputConfigResponse = ( dv: DataView ): IReadInputConfigResponse => {
    return {
        inputLimitUpper: dv.getUint32( 4, true ),
        inputLimitLower: dv.getUint32( 8, true ),
        inputLimitCenter: dv.getUint32( 12, true ),
        inputMode: dv.getUint8( 16 ),
        alwaysOn: dv.getUint8( 17 ),
    };
};

export const deserializeReadPowerConfigRequest = ( dv: DataView ): IReadPowerConfigRequest => {
    return {
    };
};

export const deserializeReadPowerConfigResponse = ( dv: DataView ): IReadPowerConfigResponse => {
    return {
        motorCurrentLimit: dv.getFloat32( 4, true ),
        batteryCurrentLimit: dv.getFloat32( 8, true ),
        overvoltageThreshold: dv.getFloat32( 12, true ),
        undervoltageThreshold: dv.getFloat32( 16, true ),
        overcurrentThreshold: dv.getFloat32( 20, true ),
        overtemperatureThreshold: dv.getFloat32( 24, true ),
    };
};

export const deserializeWriteParametersRequest = ( dv: DataView ): IWriteParametersRequest => {
    return {
        resistance: dv.getFloat32( 4, true ),
        ld: dv.getFloat32( 8, true ),
        lq: dv.getFloat32( 12, true ),
        fluxLinkage: dv.getFloat32( 16, true ),
    };
};

export const deserializeWriteParametersResponse = ( dv: DataView ): IWriteParametersResponse => {
    return {
        resistance: dv.getFloat32( 4, true ),
        ld: dv.getFloat32( 8, true ),
        lq: dv.getFloat32( 12, true ),
        fluxLinkage: dv.getFloat32( 16, true ),
    };
};

export const deserializeWriteControlConfigRequest = ( dv: DataView ): IWriteControlConfigRequest => {
    return {
        motorMaxSpeed: dv.getFloat32( 4, true ),
        polePairCount: dv.getFloat32( 8, true ),
        speedControlProportionalGain: dv.getFloat32( 12, true ),
        speedControlIntegralGain: dv.getFloat32( 16, true ),
        positionControlProportionalGain: dv.getFloat32( 20, true ),
        controlMode: dv.getUint8( 24 ),
        mtpa: dv.getInt8( 25 ),
        fluxWeakening: dv.getInt8( 26 ),
    };
};

export const deserializeWriteControlConfigResponse = ( dv: DataView ): IWriteControlConfigResponse => {
    return {
        motorMaxSpeed: dv.getFloat32( 4, true ),
        polePairCount: dv.getFloat32( 8, true ),
        speedControlProportionalGain: dv.getFloat32( 12, true ),
        speedControlIntegralGain: dv.getFloat32( 16, true ),
        positionControlProportionalGain: dv.getFloat32( 20, true ),
        controlMode: dv.getUint8( 24 ),
        mtpa: dv.getInt8( 25 ),
        fluxWeakening: dv.getInt8( 26 ),
    };
};

export const deserializeWriteSensorModeRequest = ( dv: DataView ): IWriteSensorModeRequest => {
    return {
        sensorMode: dv.getUint8( 4 ),
        encoderCpr: dv.getUint32( 5, true ),
    };
};

export const deserializeWriteSensorModeResponse = ( dv: DataView ): IWriteSensorModeResponse => {
    return {
        sensorMode: dv.getUint8( 4 ),
        encoderCpr: dv.getUint32( 5, true ),
    };
};

export const deserializeReadSensorModeRequest = ( dv: DataView ): IReadSensorModeRequest => {
    return {
    };
};

export const deserializeReadSensorModeResponse = ( dv: DataView ): IReadSensorModeResponse => {
    return {
        sensorMode: dv.getUint8( 4 ),
        encoderCpr: dv.getUint32( 5, true ),
        encoderOffset: dv.getFloat32( 9, true ),
        encoderDirection: dv.getInt8( 13 ),
    };
};

export const deserializeStartEncoderCalRequest = ( dv: DataView ): IStartEncoderCalRequest => {
    return {
    };
};

export const deserializeStartEncoderCalResponse = ( dv: DataView ): IStartEncoderCalResponse => {
    return {
    };
};

export const deserializeGetEncoderCalResultRequest = ( dv: DataView ): IGetEncoderCalResultRequest => {
    return {
    };
};

export const deserializeGetEncoderCalResultResponse = ( dv: DataView ): IGetEncoderCalResultResponse => {
    return {
        offset: dv.getFloat32( 4, true ),
        status: dv.getUint8( 8 ),
        direction: dv.getInt8( 9 ),
    };
};

export const deserializeEncoderCalResultRequest = ( dv: DataView ): IEncoderCalResultRequest => {
    return {
    };
};

export const deserializeEncoderCalResultResponse = ( dv: DataView ): IEncoderCalResultResponse => {
    return {
        offset: dv.getFloat32( 4, true ),
        status: dv.getUint8( 8 ),
        direction: dv.getInt8( 9 ),
    };
};

export const deserializeWriteOpenLoopConfigRequest = ( dv: DataView ): IWriteOpenLoopConfigRequest => {
    return {
        accelerationRamp: dv.getFloat32( 4, true ),
    };
};

export const deserializeWriteOpenLoopConfigResponse = ( dv: DataView ): IWriteOpenLoopConfigResponse => {
    return {
        accelerationRamp: dv.getFloat32( 4, true ),
    };
};

export const deserializeReadControlModeRequest = ( dv: DataView ): IReadControlModeRequest => {
    return {
    };
};

export const deserializeReadControlModeResponse = ( dv: DataView ): IReadControlModeResponse => {
    return {
        controlMode: dv.getUint8( 4 ),
    };
};

export const deserializeWriteCanIdRequest = ( dv: DataView ): IWriteCanIdRequest => {
    return {
        canId: dv.getUint32( 4, true ),
    };
};

export const deserializeWriteCanIdResponse = ( dv: DataView ): IWriteCanIdResponse => {
    return {
        canId: dv.getUint32( 4, true ),
    };
};

export const deserializeReadCanIdRequest = ( dv: DataView ): IReadCanIdRequest => {
    return {
    };
};

export const deserializeReadCanIdResponse = ( dv: DataView ): IReadCanIdResponse => {
    return {
        canId: dv.getUint32( 4, true ),
    };
};

export const deserializeWriteEscIdRequest = ( dv: DataView ): IWriteEscIdRequest => {
    return {
        escIndex: dv.getUint32( 4, true ),
    };
};

export const deserializeWriteEscIdResponse = ( dv: DataView ): IWriteEscIdResponse => {
    return {
        escIndex: dv.getUint32( 4, true ),
    };
};

export const deserializeReadEscIdRequest = ( dv: DataView ): IReadEscIdRequest => {
    return {
    };
};

export const deserializeReadEscIdResponse = ( dv: DataView ): IReadEscIdResponse => {
    return {
        escIndex: dv.getUint32( 4, true ),
    };
};

export const deserializeGetBuildInfoRequest = ( dv: DataView ): IGetBuildInfoRequest => {
    return {
    };
};

export const deserializeGetBuildInfoResponse = ( dv: DataView ): IGetBuildInfoResponse => {
    return {
        year: dv.getUint32( 4, true ),
        month: dv.getUint32( 8, true ),
        day: dv.getUint32( 12, true ),
        increment: dv.getUint32( 16, true ),
        buildType: dv.getUint8( 20 ),
        dirty: dv.getUint32( 21, true ),
    };
};

export const deserializeReadMtpaStatusRequest = ( dv: DataView ): IReadMtpaStatusRequest => {
    return {
    };
};

export const deserializeReadMtpaStatusResponse = ( dv: DataView ): IReadMtpaStatusResponse => {
    return {
        mtpa: dv.getInt8( 4 ),
    };
};

export const deserializeReadFluxWeakeningStatusRequest = ( dv: DataView ): IReadFluxWeakeningStatusRequest => {
    return {
    };
};

export const deserializeReadFluxWeakeningStatusResponse = ( dv: DataView ): IReadFluxWeakeningStatusResponse => {
    return {
        fluxWeakening: dv.getInt8( 4 ),
    };
};

export const deserializeSetUsbThrottleControlRequest = ( dv: DataView ): ISetUsbThrottleControlRequest => {
    return {
        value: dv.getUint32( 4, true ),
    };
};

export const deserializeSetUsbThrottleControlResponse = ( dv: DataView ): ISetUsbThrottleControlResponse => {
    return {
        value: dv.getUint32( 4, true ),
    };
};

export const deserializeFaultDataMessage = ( dv: DataView ): IFaultDataMessage => {
    return {
        code: dv.getUint32( 4, true ),
    };
};

export const deserializeRealtimeDataMessage = ( dv: DataView ): IRealtimeDataMessage => {
    return {
        motorSpeed: dv.getFloat32( 4, true ),
        motorCurrent: dv.getFloat32( 8, true ),
        batteryVoltage: dv.getFloat32( 12, true ),
        batteryCurrent: dv.getFloat32( 16, true ),
        servoPulse: dv.getFloat32( 20, true ),
        inverterTemp: dv.getFloat32( 24, true ),
        controllerState: dv.getUint8( 28 ),
        faultCode: dv.getUint8( 29 ),
        uptimeMs: dv.getUint32( 30, true ),
    };
};

export const deserializeFirmwareUpdateRequest = ( dv: DataView ): IFirmwareUpdateRequest => {
    return {
    };
};

export const deserializeFirmwareUpdateResponse = ( dv: DataView ): IFirmwareUpdateResponse => {
    return {
    };
};

export const deserializeUserDefinedMessage = ( dv: DataView ): IUserDefinedMessage => {
    return {
    };
};

export interface IReceivedPacketTypes {
    [ PacketId.INVALID ]: IInvalidResponse;
    [ PacketId.HANDSHAKE ]: IHandshakeResponse;
    [ PacketId.START_PARAM_DETECT ]: IStartParamDetectResponse;
    [ PacketId.WRITE_INPUT_CONFIG ]: IWriteInputConfigResponse;
    [ PacketId.WRITE_POWER_CONFIG ]: IWritePowerConfigResponse;
    [ PacketId.READ_FAULT ]: IReadFaultResponse;
    [ PacketId.READ_TELEMETRY ]: IReadTelemetryResponse;
    [ PacketId.READ_MOTOR_PARAM ]: IReadMotorParamResponse;
    [ PacketId.READ_INPUT_CONFIG ]: IReadInputConfigResponse;
    [ PacketId.READ_POWER_CONFIG ]: IReadPowerConfigResponse;
    [ PacketId.WRITE_PARAMETERS ]: IWriteParametersResponse;
    [ PacketId.WRITE_CONTROL_CONFIG ]: IWriteControlConfigResponse;
    [ PacketId.WRITE_SENSOR_MODE ]: IWriteSensorModeResponse;
    [ PacketId.READ_SENSOR_MODE ]: IReadSensorModeResponse;
    [ PacketId.START_ENCODER_CAL ]: IStartEncoderCalResponse;
    [ PacketId.GET_ENCODER_CAL_RESULT ]: IGetEncoderCalResultResponse;
    [ PacketId.ENCODER_CAL_RESULT ]: IEncoderCalResultResponse;
    [ PacketId.WRITE_OPEN_LOOP_CONFIG ]: IWriteOpenLoopConfigResponse;
    [ PacketId.READ_CONTROL_MODE ]: IReadControlModeResponse;
    [ PacketId.WRITE_CAN_ID ]: IWriteCanIdResponse;
    [ PacketId.READ_CAN_ID ]: IReadCanIdResponse;
    [ PacketId.WRITE_ESC_ID ]: IWriteEscIdResponse;
    [ PacketId.READ_ESC_ID ]: IReadEscIdResponse;
    [ PacketId.GET_BUILD_INFO ]: IGetBuildInfoResponse;
    [ PacketId.READ_MTPA_STATUS ]: IReadMtpaStatusResponse;
    [ PacketId.READ_FLUX_WEAKENING_STATUS ]: IReadFluxWeakeningStatusResponse;
    [ PacketId.SET_USB_THROTTLE_CONTROL ]: ISetUsbThrottleControlResponse;
    [ PacketId.FAULT_DATA ]: IFaultDataMessage;
    [ PacketId.REALTIME_DATA ]: IRealtimeDataMessage;
    [ PacketId.FIRMWARE_UPDATE ]: IFirmwareUpdateResponse;
    [ PacketId.USER_DEFINED ]: IUserDefinedMessage;
}

export type PacketKeys<I extends PacketId> = keyof IReceivedPacketTypes[ I ];

type DisplayUnits = { [ I in PacketId ]: { [ K in PacketKeys<I> ]: string } };
export const displayUnits: DisplayUnits = {
    [ PacketId.INVALID ]: {
    },
    [ PacketId.HANDSHAKE ]: {
        versionMajor: "",
        versionMinor: "",
        versionBuild: "",
        resistance: "ohms",
        ld: "H",
        lq: "H",
        fluxLinkage: "Wb",
        gitHash: "",
        deviceId1: "",
        deviceId2: "",
        deviceId3: "",
    },
    [ PacketId.START_PARAM_DETECT ]: {
        resistance: "ohms",
        ld: "H",
        lq: "H",
        fluxLinkage: "Wb",
    },
    [ PacketId.WRITE_INPUT_CONFIG ]: {
        inputLimitUpper: "",
        inputLimitLower: "",
        inputLimitCenter: "",
        inputMode: "",
        alwaysOn: "",
    },
    [ PacketId.WRITE_POWER_CONFIG ]: {
        motorCurrentLimit: "A",
        batteryCurrentLimit: "A",
        overvoltageThreshold: "V",
        undervoltageThreshold: "V",
        overcurrentThreshold: "A",
        overtemperatureThreshold: "°C",
    },
    [ PacketId.READ_FAULT ]: {
    },
    [ PacketId.READ_TELEMETRY ]: {
    },
    [ PacketId.READ_MOTOR_PARAM ]: {
    },
    [ PacketId.READ_INPUT_CONFIG ]: {
        inputLimitUpper: "",
        inputLimitLower: "",
        inputLimitCenter: "",
        inputMode: "",
        alwaysOn: "",
    },
    [ PacketId.READ_POWER_CONFIG ]: {
        motorCurrentLimit: "A",
        batteryCurrentLimit: "A",
        overvoltageThreshold: "V",
        undervoltageThreshold: "V",
        overcurrentThreshold: "A",
        overtemperatureThreshold: "°C",
    },
    [ PacketId.WRITE_PARAMETERS ]: {
        resistance: "ohms",
        ld: "H",
        lq: "H",
        fluxLinkage: "Wb",
    },
    [ PacketId.WRITE_CONTROL_CONFIG ]: {
        motorMaxSpeed: "rad/s",
        polePairCount: "",
        speedControlProportionalGain: "N-m/s/rad",
        speedControlIntegralGain: "N-m/rad",
        positionControlProportionalGain: "/s",
        controlMode: "",
        mtpa: "",
        fluxWeakening: "",
    },
    [ PacketId.WRITE_SENSOR_MODE ]: {
        sensorMode: "",
        encoderCpr: "",
    },
    [ PacketId.READ_SENSOR_MODE ]: {
        sensorMode: "",
        encoderCpr: "",
        encoderOffset: "",
        encoderDirection: "",
    },
    [ PacketId.START_ENCODER_CAL ]: {
    },
    [ PacketId.GET_ENCODER_CAL_RESULT ]: {
        offset: "radians",
        status: "",
        direction: "",
    },
    [ PacketId.ENCODER_CAL_RESULT ]: {
        offset: "radians",
        status: "",
        direction: "",
    },
    [ PacketId.WRITE_OPEN_LOOP_CONFIG ]: {
        accelerationRamp: "erad/s^2",
    },
    [ PacketId.READ_CONTROL_MODE ]: {
        controlMode: "",
    },
    [ PacketId.WRITE_CAN_ID ]: {
        canId: "",
    },
    [ PacketId.READ_CAN_ID ]: {
        canId: "",
    },
    [ PacketId.WRITE_ESC_ID ]: {
        escIndex: "",
    },
    [ PacketId.READ_ESC_ID ]: {
        escIndex: "",
    },
    [ PacketId.GET_BUILD_INFO ]: {
        year: "",
        month: "",
        day: "",
        increment: "",
        buildType: "",
        dirty: "",
    },
    [ PacketId.READ_MTPA_STATUS ]: {
        mtpa: "",
    },
    [ PacketId.READ_FLUX_WEAKENING_STATUS ]: {
        fluxWeakening: "",
    },
    [ PacketId.SET_USB_THROTTLE_CONTROL ]: {
        value: "",
    },
    [ PacketId.FAULT_DATA ]: {
        code: "",
    },
    [ PacketId.REALTIME_DATA ]: {
        motorSpeed: "RPM",
        motorCurrent: "A",
        batteryVoltage: "V",
        batteryCurrent: "A",
        servoPulse: "",
        inverterTemp: "°C",
        controllerState: "",
        faultCode: "",
        uptimeMs: "ms",
    },
    [ PacketId.FIRMWARE_UPDATE ]: {
    },
    [ PacketId.USER_DEFINED ]: {
    },
};

export const fullDisplayUnits: DisplayUnits = {
    [ PacketId.INVALID ]: {
    },
    [ PacketId.HANDSHAKE ]: {
        versionMajor: "",
        versionMinor: "",
        versionBuild: "",
        resistance: "ohms",
        ld: "henry",
        lq: "henry",
        fluxLinkage: "weber",
        gitHash: "",
        deviceId1: "",
        deviceId2: "",
        deviceId3: "",
    },
    [ PacketId.START_PARAM_DETECT ]: {
        resistance: "ohms",
        ld: "henry",
        lq: "henry",
        fluxLinkage: "weber",
    },
    [ PacketId.WRITE_INPUT_CONFIG ]: {
        inputLimitUpper: "",
        inputLimitLower: "",
        inputLimitCenter: "",
        inputMode: "",
        alwaysOn: "",
    },
    [ PacketId.WRITE_POWER_CONFIG ]: {
        motorCurrentLimit: "amps",
        batteryCurrentLimit: "amps",
        overvoltageThreshold: "volts",
        undervoltageThreshold: "volts",
        overcurrentThreshold: "amps",
        overtemperatureThreshold: "celsius",
    },
    [ PacketId.READ_FAULT ]: {
    },
    [ PacketId.READ_TELEMETRY ]: {
    },
    [ PacketId.READ_MOTOR_PARAM ]: {
    },
    [ PacketId.READ_INPUT_CONFIG ]: {
        inputLimitUpper: "",
        inputLimitLower: "",
        inputLimitCenter: "",
        inputMode: "",
        alwaysOn: "",
    },
    [ PacketId.READ_POWER_CONFIG ]: {
        motorCurrentLimit: "amps",
        batteryCurrentLimit: "amps",
        overvoltageThreshold: "volts",
        undervoltageThreshold: "volts",
        overcurrentThreshold: "amps",
        overtemperatureThreshold: "celsius",
    },
    [ PacketId.WRITE_PARAMETERS ]: {
        resistance: "ohms",
        ld: "henry",
        lq: "henry",
        fluxLinkage: "weber",
    },
    [ PacketId.WRITE_CONTROL_CONFIG ]: {
        motorMaxSpeed: "mechanical rad/s",
        polePairCount: "",
        speedControlProportionalGain: "Newton-meter second per radian",
        speedControlIntegralGain: "Newton-meter per radian",
        positionControlProportionalGain: "per second",
        controlMode: "",
        mtpa: "",
        fluxWeakening: "",
    },
    [ PacketId.WRITE_SENSOR_MODE ]: {
        sensorMode: "",
        encoderCpr: "",
    },
    [ PacketId.READ_SENSOR_MODE ]: {
        sensorMode: "",
        encoderCpr: "",
        encoderOffset: "",
        encoderDirection: "",
    },
    [ PacketId.START_ENCODER_CAL ]: {
    },
    [ PacketId.GET_ENCODER_CAL_RESULT ]: {
        offset: "radians",
        status: "",
        direction: "",
    },
    [ PacketId.ENCODER_CAL_RESULT ]: {
        offset: "radians",
        status: "",
        direction: "",
    },
    [ PacketId.WRITE_OPEN_LOOP_CONFIG ]: {
        accelerationRamp: "electrical radians per second squared",
    },
    [ PacketId.READ_CONTROL_MODE ]: {
        controlMode: "",
    },
    [ PacketId.WRITE_CAN_ID ]: {
        canId: "",
    },
    [ PacketId.READ_CAN_ID ]: {
        canId: "",
    },
    [ PacketId.WRITE_ESC_ID ]: {
        escIndex: "",
    },
    [ PacketId.READ_ESC_ID ]: {
        escIndex: "",
    },
    [ PacketId.GET_BUILD_INFO ]: {
        year: "",
        month: "",
        day: "",
        increment: "",
        buildType: "",
        dirty: "",
    },
    [ PacketId.READ_MTPA_STATUS ]: {
        mtpa: "",
    },
    [ PacketId.READ_FLUX_WEAKENING_STATUS ]: {
        fluxWeakening: "",
    },
    [ PacketId.SET_USB_THROTTLE_CONTROL ]: {
        value: "",
    },
    [ PacketId.FAULT_DATA ]: {
        code: "",
    },
    [ PacketId.REALTIME_DATA ]: {
        motorSpeed: "RPM",
        motorCurrent: "amps",
        batteryVoltage: "volts",
        batteryCurrent: "amps",
        servoPulse: "",
        inverterTemp: "celsius",
        controllerState: "",
        faultCode: "",
        uptimeMs: "",
    },
    [ PacketId.FIRMWARE_UPDATE ]: {
    },
    [ PacketId.USER_DEFINED ]: {
    },
};

type DisplayColors = { [ I in PacketId ]: { [ K in PacketKeys<I> ]: string } };
export const displayColors: DisplayColors = {
    [ PacketId.INVALID ]: {
    },
    [ PacketId.HANDSHAKE ]: {
        versionMajor: "",
        versionMinor: "",
        versionBuild: "",
        resistance: "",
        ld: "",
        lq: "",
        fluxLinkage: "",
        gitHash: "",
        deviceId1: "",
        deviceId2: "",
        deviceId3: "",
    },
    [ PacketId.START_PARAM_DETECT ]: {
        resistance: "",
        ld: "",
        lq: "",
        fluxLinkage: "",
    },
    [ PacketId.WRITE_INPUT_CONFIG ]: {
        inputLimitUpper: "",
        inputLimitLower: "",
        inputLimitCenter: "",
        inputMode: "",
        alwaysOn: "",
    },
    [ PacketId.WRITE_POWER_CONFIG ]: {
        motorCurrentLimit: "",
        batteryCurrentLimit: "",
        overvoltageThreshold: "",
        undervoltageThreshold: "",
        overcurrentThreshold: "",
        overtemperatureThreshold: "",
    },
    [ PacketId.READ_FAULT ]: {
    },
    [ PacketId.READ_TELEMETRY ]: {
    },
    [ PacketId.READ_MOTOR_PARAM ]: {
    },
    [ PacketId.READ_INPUT_CONFIG ]: {
        inputLimitUpper: "",
        inputLimitLower: "",
        inputLimitCenter: "",
        inputMode: "",
        alwaysOn: "",
    },
    [ PacketId.READ_POWER_CONFIG ]: {
        motorCurrentLimit: "",
        batteryCurrentLimit: "",
        overvoltageThreshold: "",
        undervoltageThreshold: "",
        overcurrentThreshold: "",
        overtemperatureThreshold: "",
    },
    [ PacketId.WRITE_PARAMETERS ]: {
        resistance: "",
        ld: "",
        lq: "",
        fluxLinkage: "",
    },
    [ PacketId.WRITE_CONTROL_CONFIG ]: {
        motorMaxSpeed: "",
        polePairCount: "",
        speedControlProportionalGain: "",
        speedControlIntegralGain: "",
        positionControlProportionalGain: "",
        controlMode: "",
        mtpa: "",
        fluxWeakening: "",
    },
    [ PacketId.WRITE_SENSOR_MODE ]: {
        sensorMode: "",
        encoderCpr: "",
    },
    [ PacketId.READ_SENSOR_MODE ]: {
        sensorMode: "",
        encoderCpr: "",
        encoderOffset: "",
        encoderDirection: "",
    },
    [ PacketId.START_ENCODER_CAL ]: {
    },
    [ PacketId.GET_ENCODER_CAL_RESULT ]: {
        offset: "",
        status: "",
        direction: "",
    },
    [ PacketId.ENCODER_CAL_RESULT ]: {
        offset: "",
        status: "",
        direction: "",
    },
    [ PacketId.WRITE_OPEN_LOOP_CONFIG ]: {
        accelerationRamp: "",
    },
    [ PacketId.READ_CONTROL_MODE ]: {
        controlMode: "",
    },
    [ PacketId.WRITE_CAN_ID ]: {
        canId: "",
    },
    [ PacketId.READ_CAN_ID ]: {
        canId: "",
    },
    [ PacketId.WRITE_ESC_ID ]: {
        escIndex: "",
    },
    [ PacketId.READ_ESC_ID ]: {
        escIndex: "",
    },
    [ PacketId.GET_BUILD_INFO ]: {
        year: "",
        month: "",
        day: "",
        increment: "",
        buildType: "",
        dirty: "",
    },
    [ PacketId.READ_MTPA_STATUS ]: {
        mtpa: "",
    },
    [ PacketId.READ_FLUX_WEAKENING_STATUS ]: {
        fluxWeakening: "",
    },
    [ PacketId.SET_USB_THROTTLE_CONTROL ]: {
        value: "",
    },
    [ PacketId.FAULT_DATA ]: {
        code: "",
    },
    [ PacketId.REALTIME_DATA ]: {
        motorSpeed: "#f74943",
        motorCurrent: "#f7faa2",
        batteryVoltage: "#2cded8",
        batteryCurrent: "#8d8df0",
        servoPulse: "#efc0fc",
        inverterTemp: "#faa114",
        controllerState: "",
        faultCode: "",
        uptimeMs: "#3dba5b",
    },
    [ PacketId.FIRMWARE_UPDATE ]: {
    },
    [ PacketId.USER_DEFINED ]: {
    },
};

export const inputReportID = ( dv: DataView ): PacketId => {
    return dv.getUint8( 0 );
};

export const deserialize = ( dv: DataView ): IReceivedPacketTypes[ PacketId ] => {
    switch ( inputReportID( dv ) ) {
        case PacketId.INVALID: return deserializeInvalidResponse( dv );
        case PacketId.HANDSHAKE: return deserializeHandshakeResponse( dv );
        case PacketId.START_PARAM_DETECT: return deserializeStartParamDetectResponse( dv );
        case PacketId.WRITE_INPUT_CONFIG: return deserializeWriteInputConfigResponse( dv );
        case PacketId.WRITE_POWER_CONFIG: return deserializeWritePowerConfigResponse( dv );
        case PacketId.READ_FAULT: return deserializeReadFaultResponse( dv );
        case PacketId.READ_TELEMETRY: return deserializeReadTelemetryResponse( dv );
        case PacketId.READ_MOTOR_PARAM: return deserializeReadMotorParamResponse( dv );
        case PacketId.READ_INPUT_CONFIG: return deserializeReadInputConfigResponse( dv );
        case PacketId.READ_POWER_CONFIG: return deserializeReadPowerConfigResponse( dv );
        case PacketId.WRITE_PARAMETERS: return deserializeWriteParametersResponse( dv );
        case PacketId.WRITE_CONTROL_CONFIG: return deserializeWriteControlConfigResponse( dv );
        case PacketId.WRITE_SENSOR_MODE: return deserializeWriteSensorModeResponse( dv );
        case PacketId.READ_SENSOR_MODE: return deserializeReadSensorModeResponse( dv );
        case PacketId.START_ENCODER_CAL: return deserializeStartEncoderCalResponse( dv );
        case PacketId.GET_ENCODER_CAL_RESULT: return deserializeGetEncoderCalResultResponse( dv );
        case PacketId.ENCODER_CAL_RESULT: return deserializeEncoderCalResultResponse( dv );
        case PacketId.WRITE_OPEN_LOOP_CONFIG: return deserializeWriteOpenLoopConfigResponse( dv );
        case PacketId.READ_CONTROL_MODE: return deserializeReadControlModeResponse( dv );
        case PacketId.WRITE_CAN_ID: return deserializeWriteCanIdResponse( dv );
        case PacketId.READ_CAN_ID: return deserializeReadCanIdResponse( dv );
        case PacketId.WRITE_ESC_ID: return deserializeWriteEscIdResponse( dv );
        case PacketId.READ_ESC_ID: return deserializeReadEscIdResponse( dv );
        case PacketId.GET_BUILD_INFO: return deserializeGetBuildInfoResponse( dv );
        case PacketId.READ_MTPA_STATUS: return deserializeReadMtpaStatusResponse( dv );
        case PacketId.READ_FLUX_WEAKENING_STATUS: return deserializeReadFluxWeakeningStatusResponse( dv );
        case PacketId.SET_USB_THROTTLE_CONTROL: return deserializeSetUsbThrottleControlResponse( dv );
        case PacketId.FAULT_DATA: return deserializeFaultDataMessage( dv );
        case PacketId.REALTIME_DATA: return deserializeRealtimeDataMessage( dv );
        case PacketId.FIRMWARE_UPDATE: return deserializeFirmwareUpdateResponse( dv );
        case PacketId.USER_DEFINED: return deserializeUserDefinedMessage( dv );
    }
};
